.content-title {
  margin-bottom: 22px;
}

.form-group {
  margin-bottom: 25px;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #0b0b0b inset;
  -webkit-text-fill-color: #777 !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control {
  color: #777;
  background-color: var(--bg_component_color);
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #1d1d1d;
  font-size: 15px;
  height: 44px;
  padding: 9px 14px;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid var(--border_color);
  background-color: var(--bg_component_color);
  color: #fff;
}

#contact-form-msg {
  height: auto;
}

.form-submit .submit-button {
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 30px;
  padding: 10px 26px;
}

#submit-button-default {
  background-color: var(--button_default) !important;
  color: #101010;
  border: 1px solid var(--button_default) !important;
}

#submit-button-default:hover {
  background-color: var(--selection_color) !important;
  color: #101010;
  border: 1px solid var(--selection_color) !important;
}

#submit-button-loading {
  background-color: var(--icon_color) !important;
  border: 1px solid var(--icon_color) !important;
  opacity: 1 i !important;
  color: #101010 !important;
}

.submit-success-msg {
  display: none;
  margin-top: 8px;
  color: var(--highlight_color);
}

.msg-show {
  display: block;
}

.submit-error {
  display: block;
  color: #ff2211;
}
