.section {
  height: 100vh;
  background: url("../../images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 35px;
}

.section__name {
  color: var(--icon_hover_color);
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 1px;
}

.section__subtitle {
  color: var(--home_desc_color);
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.3px;
  justify-content: center;
  display: flex;
}

.section__subtitle--name {
  color: var(--icon_hover_color);
  display: block;
}

.section__subtitle > span {
  display: flex;
  flex-direction: column;
}

.social-icon-list {
  margin-top: 14px;
}
