.about {
  padding-top: 70px;
}

.info-profile {
  border-radius: 50%;
  width: 250px;
  transition: all 0.2s ease-in-out;
}

.info-profile:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.content-subtitle {
  color: var(--highlight_color);
  font-size: 24px;
  margin-bottom: 15px;
}

.content-title {
  color: var(--content_title_color);
  font-size: 26px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 16px;
}

.content-description p:last-child {
  border-bottom: 2px solid;
  border-bottom-color: var(--divider_border_color);
  margin-bottom: 0;
  padding-bottom: 29px;
}

.address-content {
  padding-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 0;
  border-bottom: 2px solid;
  border-bottom-color: var(--divider_border_color);
}

a {
  transition: all 0.15s ease-in-out;
}

.address-info > span {
  color: #dadada;
  font-weight: 400;
  margin-right: 10px;
}

.address-info > p {
  display: inline-block;
}

.address-info > p > a {
  color: var(--highlight_color);
  text-decoration-line: none;
}

.address-info > p > a:hover {
  color: var(--highlight_hover_color);
}

.social-icon {
  padding: 20px 0;
}
